function App() {
	return (
		<section className='homepage'>
			<div className='container'>
				<div className='row'>
					<div className='col-xs-12'>
						<img src={require('./assets/Logo-CES.png')} alt='CES Management' />
						<p>Ce site est acteullement en construction</p>
						<a href='mailto:info@ces-management.ch'>Contactez-nous</a>
					</div>
				</div>
			</div>
		</section>
	);
}

export default App;
